<template>
  <div class="grid place-items-center h-screen z-50">
    <div class="grid place-items-center bg-gray-900 text-white p-5 rounded-lg">
      <div>
        <img
          src="../../assets/TB Logo.png"
          alt="TB Logo"
          class="w-20 h-20 rounded mb-10"
        />
      </div>
      <svg
        :class="`animate-spin h-20 w-20 text-white`"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <p class="text-center mt-10 font-bold text-xl">Loading... please wait.</p>
    </div>
  </div>
</template>
