import { createApp } from "vue";
import App from "./App.vue";
import router from "../src/router/index";
import "./index.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { projectAuth } from "./firebase/config";
import store from "./store";
import { LottieAnimation } from "lottie-web-vue";
import VueApexCharts from "vue3-apexcharts";
import VueYtframe from "vue3-ytframe";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

let app;
projectAuth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);
    
    app.use(store);
    app.use(router);
    app.use(VueGoogleMaps, {
      load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        libraries: "places",
      },
    });
    app.use(LottieAnimation);
    app.use(VueApexCharts);
    app.use(VueYtframe);
    app.use(Toast);
    
    app.mount("#app");
  }
});
