import { createRouter, createWebHistory } from "vue-router";
import { projectAuth } from "../firebase/config";

// auth guard

const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  if (!user) {
    next({ name: "Login" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/newLogin.vue"),
  },
  {
    path: "/affiliate-program",
    name: "Affiliates",
    component: () =>
      import(/* webpackChunkName: "affiliates" */ "../views/Affiliates.vue"),
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () =>
      import(/* webpackChunkName: "pricing" */ "../views/Pricing.vue"),
  },
  {
    path: "/apply",
    name: "Apply",
    component: () =>
      import(/* webpackChunkName: "apply" */ "../views/Apply.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ "../views/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/tos",
    name: "TOS",
    component: () => import(/* webpackChunkName: "tos" */ "../views/TOS.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
