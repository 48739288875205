import { initializeApp } from "firebase/app";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyClgVyptAl5AZzHWLOLIr-8fnnhE0l4zf0",
  authDomain: "s3-tint.firebaseapp.com",
  projectId: "s3-tint",
  storageBucket: "s3-tint.appspot.com",
  messagingSenderId: "866830665113",
  appId: "1:866830665113:web:33a2cc81bfbb3cfaa37f08",
  measurementId: "G-69LMM2FZVV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Init services
const projectFirestore = getFirestore(app);
const projectAuth = getAuth(app);
const projectStorage = getStorage(app);
const functions = getFunctions(app);

// Timestamp
const timestamp = serverTimestamp();

export {
  app,
  projectFirestore,
  projectAuth,
  projectStorage,
  timestamp,
  functions,
};
